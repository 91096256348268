import React, { createContext, useContext } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import { Account } from '../lib/Account';
import { BiPageConfig } from './lib';
import { PowerBiApi, UseLazyQuery } from './api';

const useGetEntitiesStub = <T,>() =>
  [() => Promise.resolve(undefined), { loading: false, data: undefined }] as ReturnType<
    UseLazyQuery<object, Maybe<T[]>>
  >;

const PowerBiApiNoop: PowerBiApi = {
  useWorkspaces: () => ({ data: [], loading: false }),
  useGetWorkspaceReportsLazy: () => [() => Promise.resolve([]), { loading: false, data: [] }],
  useGetPagesLazy: () => [() => Promise.resolve([]), { loading: false, data: [] }],
  useGetReportLazy: () => [() => Promise.resolve(undefined), { loading: false, data: undefined }],
  useGetPageLazy: () => [() => Promise.resolve(undefined), { loading: false, data: undefined }],
  useGetDatasetValuesLazy: useGetEntitiesStub,
  useGetDatasetTablesLazy: useGetEntitiesStub,
  useGetDatasetTableColumnsLazy: useGetEntitiesStub,
};

export type BiReportContextInterface = {
  account: Account;
  getBiPageUrl: (pageConfig: BiPageConfig) => string;
  powerBiApi: PowerBiApi;
};

export const BiReportContext = createContext<BiReportContextInterface>({
  account: { type: 'platform', accountId: '' },
  getBiPageUrl: () => '',
  powerBiApi: PowerBiApiNoop,
});

export const BiReportContextProvider: FCC<BiReportContextInterface> = ({ children, ...value }) => {
  return <BiReportContext.Provider value={value}>{children}</BiReportContext.Provider>;
};

export default BiReportContextProvider;

export const useBiReportContext = () => useContext(BiReportContext);
export const usePowerBiApi = () => useBiReportContext().powerBiApi;
