import React from 'react';
import Accounts from '../../../AccountManager';
import GlobalAdminTemplate from '../templates/GlobalAdminTemplate';
import FullWidthLayout from '../../components/layout/FullWidthLayout';
import { lazyWithRetry } from '../../../lib';

const RenderAccounts = lazyWithRetry<typeof Accounts>(
  () => import(/* webpackChunkName: "accounts" */ '../../../AccountManager'),
);

export default function AccountsPage() {
  return (
    <GlobalAdminTemplate Layout={FullWidthLayout}>
      <RenderAccounts />
    </GlobalAdminTemplate>
  );
}
