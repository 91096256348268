import { useEffect, useState } from 'react';
import { get, set } from 'idb-keyval';
import _ from 'lodash';
import { Store } from '../IndexDBStores';
import logger from '../../../../services/logger';
import useEventCallback from '../../utils/useEventCallback';

type PartialPreferencesDictionary<T extends object> = Partial<{
  [K in keyof T]: Partial<T[K]>;
}>;

const getPreferences = async <T extends object>(key: string, defaultPreferences: T): Promise<T> => {
  return get(key, Store.GeneralSettings).then(preferences => {
    return _.merge({}, defaultPreferences, preferences);
  });
};

export type UsePreferencesReturn<T extends object> = {
  preferences: T;
  setPreferences: (nextPreferences: T) => void;
  updatePreferences: (nextPreferences: PartialPreferencesDictionary<T>) => void;
  loading: boolean;
};

const usePreferences = <T extends object>(
  key: string,
  defaultPreferences: T,
): UsePreferencesReturn<T> => {
  const [preferences, setPreferences] = useState<T>(defaultPreferences);
  const [loading, setLoading] = useState(true);

  const init = useEventCallback(() => {
    getPreferences(key, defaultPreferences)
      .then(setPreferences)
      .catch(err => {
        logger.error('Failed to load user preferences', err);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  useEffect(() => init(), [key, init]);

  const _setPreferences = (nextPreferences: T) => {
    setPreferences(nextPreferences);
    set(key, nextPreferences, Store.GeneralSettings).catch(err => {
      logger.error('Failed to save user preferences', err);
    });
  };

  const updatePreferences = (nextPreferences: PartialPreferencesDictionary<T>) => {
    _setPreferences(_.merge({}, preferences, nextPreferences));
  };

  return {
    preferences,
    setPreferences: _setPreferences,
    updatePreferences,
    loading,
  };
};

export default usePreferences;
