import React, { useContext } from 'react';
import { noop } from '@tellurian/ts-utils';
import { FCC } from '../../../../utils/types';
import usePreferences, { UsePreferencesReturn } from './usePreferences';

const userPreferencesContextFactory = <T extends object>(key: string, defaultPreferences: T) => {
  const Context = React.createContext<UsePreferencesReturn<T>>({
    preferences: defaultPreferences,
    setPreferences: noop,
    updatePreferences: noop,
    loading: false,
  });
  const Provider: FCC = ({ children }) => {
    return (
      <Context.Provider value={usePreferences(key, defaultPreferences)}>
        {children}
      </Context.Provider>
    );
  };

  const usePreferencesContext = () => useContext(Context);

  return {
    Provider,
    usePreferencesContext,
  };
};

export default userPreferencesContextFactory;
