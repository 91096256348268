import { AnalyticsEvent, useTrackPartnerAnalyticsEvent } from '../tracking';
import useEventCallback from '../../utils/useEventCallback';
import { Resource } from '../../components/ResourceCards/lib';
import { PartnerResources } from '../PartnerResources';

type ResourceTitle = (typeof PartnerResources)[number]['title'];

const ResourceTargetToNavEvent: Partial<Record<ResourceTitle, AnalyticsEvent>> = {
  'Knowledge Base': 'mini-site|nav|resources|KB',
  Academy: 'mini-site|nav|resources|Academy',
  'Recent Updates': 'mini-site|nav|resources|Recent-updates',
};

const ResourceTargetToHomeTileEvent: Partial<Record<ResourceTitle, AnalyticsEvent>> = {
  'Knowledge Base': 'mini-site|resources-tile|KB',
  Academy: 'mini-site|resources-tile|Academy',
  'Recent Updates': 'mini-site|resources-tile|Recent-updates',
};

const useTrackResourceEvent = (navEvents = false) => {
  const trackEvent = useTrackPartnerAnalyticsEvent();
  return useEventCallback((resource: Resource) => {
    const eventName = (navEvents ? ResourceTargetToNavEvent : ResourceTargetToHomeTileEvent)[
      resource.title
    ];
    if (eventName) {
      trackEvent(eventName);
    }
  });
};

export default useTrackResourceEvent;
