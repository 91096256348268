import React from 'react';
import PageContainer from '../../../../../pageTemplates/PageContainer';
import ContactUs from '../../../../Links/ContactUs';
import Flex from '../../../components/Flex';
import { NotificationState, NotificationType } from '../../../components/lib/notifications/lib';
import NotificationCard from '../../../components/NotificationCard';
import { Theme } from '../../../components/Theme';
import ThemeProvider from '../../../components/Theme/ThemeProvider';

const AuthConfigurationError = ({
  error,
  partnerApplication,
}: {
  error: string;
  partnerApplication: string | undefined;
}) => {
  return (
    <ThemeProvider theme={Theme.Dot}>
      <PageContainer>
        <Flex spaceBetween column>
          <NotificationCard
            state={NotificationState.Showing}
            notificationType={NotificationType.Failure}
            content={
              <>
                An error occurred whilst authenticating user. Please try again and if the problem
                persists, contact customer support.
                <ContactUs
                  subject={'Partner Authentication Configuration Error'}
                  body={`Partner Configuration Error \n\n Subdomain: ${partnerApplication || 'unknown'} \n\n Error Message: ${error}`}
                />
              </>
            }
          />
        </Flex>
      </PageContainer>
    </ThemeProvider>
  );
};

export default AuthConfigurationError;
