import { UseStore, get as idbGet, set as idbSet } from 'idb-keyval';
import { Maybe } from '@tellurian/ts-utils';
import Logger from '../../../services/logger';

type IndexDbCache = {
  get: <T>(key: string) => Promise<Maybe<T>>;
  set: <T>(key: string, value: T) => Promise<T>;
};

const indexDbCache = (store: UseStore): IndexDbCache => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const memoryCache = new Map<string, any>();

  const get = async <T>(key: string): Promise<Maybe<T>> => {
    if (memoryCache.has(key)) {
      return memoryCache.get(key);
    }

    try {
      const value = await idbGet(key, store);
      memoryCache.set(key, value);
      return value;
    } catch (e) {
      Logger.error(`Cannot retrieve IDB cached value for key ${key}.`);
    }

    return undefined;
  };

  const set = async <T>(key: string, value: T) => {
    memoryCache.set(key, value);
    try {
      await idbSet(key, value, store);
    } catch (e) {
      Logger.error(`Cannot set IDB cached value for key ${key}.`);
    }
    return value;
  };

  return { get, set };
};

export default indexDbCache;
