import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/lettuce/chart.svg';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/lettuce/help.svg';
import { ReactComponent as IconResources } from '../../../../assets/icons/lettuce/unfi/resources.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/icons/lettuce/home.svg';
import {
  ExternalLinkMenuListItem,
  MenuListItem,
  MenuListItemWithPopoverSubMenu,
} from '../../components/NavRail/menuItems';
import NavRail from '../../components/NavRail';
import { RouteId } from '../../crisp/routing/Routes';
import { path } from '../../crisp/routing/lib';
import { ExternalLinkTarget } from '../../components/ExternalLink';
import { useHelpUrl } from '../../crisp/navigation/HelpLinkContextProvider';
import CurrentUserAvatar from '../../components/Avatar/CurrentUserAvatar';
import { SubMenuType } from '../../components/NavRail/SubMenuPopover';
import { PartnerAccount } from '../../components/lib/Account';
import { PartnerResources } from '../PartnerResources';
import ResourceCards from '../../components/ResourceCards';
import Headline from '../../components/Headline';
import useTrackResourceEvent from '../lib/useTrackResourceEvent';
import { eventTrack } from '../tracking';
import UserSettingsMenu from './UserSettingsMenu';
import LogoLink from './LogoLink';
import style from './NavRailMenu.module.css';

const ResourcesMenuItems = () => {
  return (
    <div className={style.resourcesMenuItemsWrapper}>
      <Headline size="S" className="man mbm">
        Resources
      </Headline>
      <ResourceCards resources={PartnerResources} onCardClick={useTrackResourceEvent(true)} />
    </div>
  );
};

type NavRailMenuProps = {
  partnerAccount: PartnerAccount;
};

const NavRailMenu: React.FC<NavRailMenuProps> = React.memo(function NavRailMenu({
  partnerAccount,
}) {
  const { partnerApplication } = partnerAccount;
  const {
    location: { pathname },
  } = useHistory();

  const getPathProps = useCallback(
    (routeId: RouteId) => {
      const p = path(routeId)({ partnerApplication });
      return {
        path: p,
        isSelected: pathname === p,
      };
    },
    [pathname, partnerApplication],
  );
  const helpUrl = useHelpUrl();

  return (
    <NavRail alignChildren="space-between">
      <LogoLink partnerAccount={partnerAccount} />
      <ul id="nav-rail-pages" className={style.menuItems} role="menu">
        <MenuListItem
          Icon={HomeIcon}
          label="Home"
          onMouseDown={eventTrack('mini-site|nav|home')}
          {...getPathProps('PartnerHome')}
        />
        <MenuListItem
          Icon={ChartIcon}
          label="Analytics"
          onMouseDown={eventTrack('mini-site|nav|analytics')}
          {...getPathProps('PartnerAnalytics')}
        />
        <MenuListItemWithPopoverSubMenu
          id="resourcesNav"
          RenderContent={ResourcesMenuItems}
          RenderIcon={() => <IconResources />}
          label="Resources"
          subMenuType={SubMenuType.Expansive}
          ariaLabel="Resources Menu"
        />
        {helpUrl && (
          <ExternalLinkMenuListItem
            Icon={HelpIcon}
            label="Help"
            href={helpUrl}
            onClick={eventTrack('mini-site|nav|help')}
            target={ExternalLinkTarget.knowledgeBase}
          />
        )}
      </ul>
      <ul id="nav-rail-settings">
        <MenuListItemWithPopoverSubMenu
          id="settingsMenu"
          RenderContent={UserSettingsMenu}
          RenderIcon={CurrentUserAvatar}
          onClick={eventTrack('mini-site|nav|settings')}
          label="Settings"
          placement="end"
        />
      </ul>
    </NavRail>
  );
});

export default NavRailMenu;
