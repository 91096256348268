import React, { useContext, useRef, useState } from 'react';
import { Maybe } from '@tellurian/ts-utils';
import { PowerBIBookmark } from '../../BiReport/bookmarks/lib';
import { FCC } from '../../../../../utils/types';
import useEventCallback from '../../../utils/useEventCallback';
import {
  useGetPlatformPowerBiBookmarkLazyQuery,
  useGetPlatformPowerBiBookmarksLazyQuery,
} from '../../../../../generated/graphql';
import indexDbCache from '../../../common/IndexDbCache';
import { Store } from '../../../common/IndexDBStores';
import { useBookmarkEventListener, useListenerContext } from '../../BiReport/ListenerProvider';
import { toPowerBIBookmark } from './lib';

type BookmarkDataContextInterface = {
  getBookmarks: (workspaceId: string) => Promise<PowerBIBookmark[]>;
  getCachedBookmarks: (workspaceId: string) => Promise<PowerBIBookmark[]>;
};

const Empty: PowerBIBookmark[] = [];

const BookmarkDataContext = React.createContext<BookmarkDataContextInterface>({
  getBookmarks: () => Promise.resolve(Empty),
  getCachedBookmarks: () => Promise.resolve(Empty),
});

const { get: getFromCache, set: setToCache } = indexDbCache(Store.ApiRequestCache);

const bookmarksCacheKey = (workspaceId: string) => `bookmarks/${workspaceId}`;

const BookmarkDataContextProvider: FCC = ({ children }) => {
  const [getBookmarksLazy] = useGetPlatformPowerBiBookmarksLazyQuery();

  const hasRetrievedFirstTimeWorkspaceIdRef = useRef<Maybe<string>>(undefined);
  const isFetchingBookmarksPromiseRef = useRef<Maybe<Promise<PowerBIBookmark[]>>>(undefined);
  const fetchBookmarks = useEventCallback(async (workspaceId: string) => {
    if (!isFetchingBookmarksPromiseRef.current) {
      hasRetrievedFirstTimeWorkspaceIdRef.current = workspaceId;
      isFetchingBookmarksPromiseRef.current = getBookmarksLazy({
        variables: { workspaceId },
        fetchPolicy: 'network-only',
      })
        .then(result => {
          const nextBookmarks = result.data?.powerBi.bookmarks.map(toPowerBIBookmark) || Empty;
          setToCache(bookmarksCacheKey(workspaceId), nextBookmarks);
          return nextBookmarks;
        })
        .finally(() => {
          isFetchingBookmarksPromiseRef.current = undefined;
        });
    }

    return isFetchingBookmarksPromiseRef.current;
  });

  const isUpdateCachePromiseRef = useRef<Maybe<Promise<PowerBIBookmark[]>>>(undefined);

  const listeners = useListenerContext().bookmarkEventListeners;
  useBookmarkEventListener(({ type, bookmark }) => {
    if (type === 'cacheUpdated') {
      return;
    }

    const workspaceId = bookmark.workspaceId;
    const cacheKey = bookmarksCacheKey(workspaceId);
    // Perform optimistic updates locally, refresh in the background
    getFromCache<PowerBIBookmark[]>(cacheKey).then(bookmarks => {
      bookmarks = bookmarks || [];
      let nextBookmarks: PowerBIBookmark[];
      if (type === 'bookmarkAdded') {
        nextBookmarks = [...bookmarks, bookmark];
      } else if (type === 'bookmarkDeleted') {
        nextBookmarks = bookmarks.filter(b => b.id !== bookmark.id);
      } else {
        nextBookmarks = [...bookmarks.filter(b => b.id !== bookmark.id), bookmark];
      }

      fetchBookmarks(workspaceId);
      return setToCache(cacheKey, nextBookmarks).then(() => {
        listeners.fire({ bookmark, type: 'cacheUpdated' });
      });
    });
  });

  const getCachedBookmarks = useEventCallback(
    async (workspaceId: string): Promise<PowerBIBookmark[]> => {
      if (isUpdateCachePromiseRef.current) {
        return isUpdateCachePromiseRef.current;
      }

      isUpdateCachePromiseRef.current = getFromCache<PowerBIBookmark[]>(
        bookmarksCacheKey(workspaceId),
      )
        .then(result => result || Empty)
        .finally(() => {
          isUpdateCachePromiseRef.current = undefined;
        });

      return isUpdateCachePromiseRef.current;
    },
  );

  const getBookmarks = useEventCallback(async (workspaceId: string): Promise<PowerBIBookmark[]> => {
    if (isFetchingBookmarksPromiseRef.current) {
      return isFetchingBookmarksPromiseRef.current;
    }

    return fetchBookmarks(workspaceId);
  });

  return (
    <BookmarkDataContext.Provider value={{ getBookmarks, getCachedBookmarks }}>
      {children}
    </BookmarkDataContext.Provider>
  );
};

export default BookmarkDataContextProvider;

export const useBookmarkDataContext = () => useContext(BookmarkDataContext);

type UseBookmarksParams = Partial<{
  workspaceId: string;
  reportId: string;
  pageName: string;
  network: boolean;
}>;

export const useBookmarks = (params: UseBookmarksParams = {}) => {
  const [bookmarks, setBookmarks] = useState<PowerBIBookmark[]>([]);
  const { getBookmarks: _getBookmarks, getCachedBookmarks } = useContext(BookmarkDataContext);
  const [loading, setLoading] = useState(false);
  const hasRetrievedBookmarksRef = useRef(false);

  const getBookmarks = useEventCallback(async (specificParams?: UseBookmarksParams) => {
    const { workspaceId, reportId, pageName, network = false } = { ...params, ...specificParams };
    if (!workspaceId) {
      // Nothing to retrieve if workspaceId is not provided
      return Promise.resolve(bookmarks);
    }

    const bookmarkFilter = (bookmark: PowerBIBookmark) => {
      return (
        (!reportId || bookmark.reportId === reportId) &&
        (!pageName || bookmark.pageName === pageName)
      );
    };
    setLoading(true);
    const willFetchViaNetwork = network || !hasRetrievedBookmarksRef.current;
    const cachedBookmarks = getCachedBookmarks(workspaceId)
      .then(bookmarks => {
        const filteredBookmarks = bookmarks.filter(bookmarkFilter);
        setBookmarks(filteredBookmarks);
        return filteredBookmarks;
      })
      .finally(() => {
        if (!willFetchViaNetwork) {
          setLoading(false);
        }
      });

    if (willFetchViaNetwork) {
      const retrievedBookmarks = _getBookmarks(workspaceId)
        .then(bookmarks => {
          const filteredBookmarks = bookmarks.filter(bookmarkFilter);
          setBookmarks(filteredBookmarks);
          return filteredBookmarks;
        })
        .finally(() => setLoading(false));
      hasRetrievedBookmarksRef.current = true;

      if (network) {
        return retrievedBookmarks;
      }
    }

    return cachedBookmarks;
  });

  return { bookmarks, getBookmarks, loading };
};

type GetBookmarkParams = Pick<PowerBIBookmark, 'workspaceId'> & {
  bookmarkId: string;
  networkOnly?: boolean;
};

export const useGetBookmark = () => {
  const [getBookmark] = useGetPlatformPowerBiBookmarkLazyQuery();
  return useEventCallback(
    async ({ workspaceId, bookmarkId, networkOnly = false }: GetBookmarkParams) => {
      if (!networkOnly) {
        const bookmarks = await getFromCache<PowerBIBookmark[]>(bookmarksCacheKey(workspaceId));
        if (bookmarks) {
          const bookmark = bookmarks.find(b => b.id === bookmarkId);
          if (bookmark) {
            return bookmark;
          }
        }
      }

      const result = await getBookmark({ variables: { bookmarkId } });
      const bookmark = result.data?.powerBi.bookmarkOrNull;
      return bookmark && toPowerBIBookmark(bookmark);
    },
  );
};
