import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import Flex from '../../components/Flex';
import Spinner from '../../components/Spinner';
import PageContainer from '../../../../pageTemplates/PageContainer';
import ThemeProvider from '../../components/Theme/ThemeProvider';
import { Theme } from '../../components/Theme';

const Logout = ({ redirectUrl }: { redirectUrl?: string }) => {
  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => window.location.replace(redirectUrl || '/'));
  }, [redirectUrl]);

  return (
    <ThemeProvider theme={Theme.Dot}>
      <PageContainer>
        <Flex spaceBetween column>
          <Spinner />
          <p>Logging out...</p>
        </Flex>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Logout;
