import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import logger from '../../../services/logger';
import SegmentTracker from '../../SegmentTracker';
import { FeatureFlagsProvider } from '../common/featureFlags/FeatureFlags';
import { AuthorizationProvider } from '../../security/AuthorizationContext';
import { PageErrorProvider } from '../../errorHandling/PageError/usePageError';
import { AuthenticationProvider } from '../../security/AuthenticationContext';
import ThemeProvider from '../components/Theme/ThemeProvider';
import Spinner from '../components/Spinner';
import ConnectorLogoContextProvider from '../components/ConnectorLogo/ConnectorLogoProvider';
import initApollo from '../../../services/initApollo';
import PersistentUIStateContextProvider from '../components/PersistentUIState';
import useDocumentTitle from '../common/useDocumentTitle';
import AppRouteSwitch from './routing/AppRouteSwitch';
import { useGetAuthConfiguration } from './authentication/useGetAuthConfiguration';
import { PartnerAuthProvider } from './authentication/PartnerAuthContext';
import Logout from './authentication/Logout';
import AuthConfigurationError from './authentication/prompts/AuthConfigurationError';
import PartnerAuthInstance from './authentication/PartnerAuthInstance';
import { PartnerUserTokenHelper } from './authentication/TokenHelper';
import { PreferencesConsoleEditor, UserPreferencesProvider } from './preferences/UserPreferences';
import { usePartnerSettings } from './PartnerSettings';

export const LOGOUT_PATH = '/p/logout';

const PARTNER_APPLICATION = 'dot';
//Temporarily removed until dot can update their callback url"
//const PARTNER_APPLICATION =  window.location.pathname.match('(?<=/p/).*?(?=/|$)')?.[0];

const App: React.FC = () => {
  const [apolloClient] = useState(initApollo({}));
  const { theme, documentTitle } = usePartnerSettings();
  useDocumentTitle(documentTitle);
  const { authConfiguration, loading, error } = useGetAuthConfiguration({
    partnerApplication: PARTNER_APPLICATION,
  });
  if (loading) return <Spinner />;

  if (!authConfiguration) {
    logger.error(`Partner Auth Error: No auth configuration found for ${PARTNER_APPLICATION}`);
    return (
      <AuthConfigurationError
        partnerApplication={PARTNER_APPLICATION}
        error={`No auth configuration found for ${PARTNER_APPLICATION}`}
      />
    );
  }

  if (window.location.pathname === LOGOUT_PATH && authConfiguration)
    return <Logout redirectUrl={authConfiguration.logoutRedirectUrl || '/'} />;

  if (error) {
    logger.error('Partner Auth Error: Error fetching auth configuration', error);
    return (
      <AuthConfigurationError partnerApplication={PARTNER_APPLICATION} error={error?.message} />
    );
  }

  return (
    <div>
      <CookiesProvider>
        <AuthenticationProvider>
          <UserPreferencesProvider>
            <PreferencesConsoleEditor />
            <PersistentUIStateContextProvider>
              <PartnerAuthInstance authConfiguration={authConfiguration}>
                <PartnerAuthProvider config={authConfiguration}>
                  <ApolloProvider client={apolloClient}>
                    <PageErrorProvider>
                      <AuthorizationProvider>
                        <PartnerUserTokenHelper>
                          <ThemeProvider theme={theme}>
                            <FeatureFlagsProvider>
                              <ConnectorLogoContextProvider>
                                <BrowserRouter>
                                  <SegmentTracker />
                                  <AppRouteSwitch />
                                </BrowserRouter>
                              </ConnectorLogoContextProvider>
                            </FeatureFlagsProvider>
                          </ThemeProvider>
                        </PartnerUserTokenHelper>
                      </AuthorizationProvider>
                    </PageErrorProvider>
                  </ApolloProvider>
                </PartnerAuthProvider>
              </PartnerAuthInstance>
            </PersistentUIStateContextProvider>
          </UserPreferencesProvider>
        </AuthenticationProvider>
      </CookiesProvider>
    </div>
  );
};

export default withLDProvider({
  clientSideID: '5d7970f8c6a4a007ae338556',
})(App);
