import React, { useEffect } from 'react';
import userPreferencesContextFactory from '../../common/preferences/userPreferencesContextFactory';
import CrispApp from '../../crisp/CrispApp';

export type UserPreferences = {
  general: {
    featuredReportsVisible: boolean;
  };
};

const DefaultPreferences: UserPreferences = {
  general: {
    featuredReportsVisible: true,
  },
};

const Versions = ['1'];
const getKey = () => `PartnerUserPreferences_v${Versions[0]}`;

const { Provider, usePreferencesContext } = userPreferencesContextFactory<UserPreferences>(
  getKey(),
  DefaultPreferences,
);

export { Provider as UserPreferencesProvider, usePreferencesContext };

export const PreferencesConsoleEditor: React.FC = () => {
  const { preferences, updatePreferences } = usePreferencesContext();
  useEffect(() => {
    CrispApp.partnerMiniSite.updatePreferences = updatePreferences;
    CrispApp.partnerMiniSite.getPreferences = () => preferences;
  }, [preferences, updatePreferences]);
  return null;
};
