import { maybe } from '@tellurian/ts-utils';
import { PlatformPowerBiBookmarkFragment } from '../../../../../generated/graphql';
import { PowerBIBookmark } from '../../BiReport/bookmarks/lib';

export const toPowerBIBookmark = ({
  id,
  name,
  filterJson,
  createdAt,
  createdByUserId,
  pageName,
  workspaceId,
  reportId,
  access,
  state,
  lastUpdatedAt,
}: PlatformPowerBiBookmarkFragment): PowerBIBookmark => {
  return {
    id,
    name,
    workspaceId,
    reportId,
    pageName,
    // Parse this lazily if performance becomes an issue later
    filters: filterJson && JSON.parse(filterJson),
    access,
    bookmarkState: maybe(state),
    createdByUserId,
    lastUpdatedAt,
    createdAt,
  };
};
