import React, { useEffect } from 'react';
import { PartnerApplicationParams } from '../lib';
import { Feature, trackFeature } from '../../../../utils/features';

type SegmentPartnerAccountTrackerProps = PartnerApplicationParams;

const SegmentPartnerAccountTracker: React.FC<SegmentPartnerAccountTrackerProps> = ({
  partnerApplication,
}) => {
  useEffect(() => {
    trackFeature(Feature.PartnerAccountAccess, { partnerApplication });
  }, [partnerApplication]);

  return null;
};

export default SegmentPartnerAccountTracker;
