import React, { SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { AnyObject, Maybe } from '@tellurian/ts-utils';
import _ from 'lodash';
import {
  getWorkspaceFriendlyName,
  PowerBiRequest,
  ReportType,
} from '../../bi/PowerBiRestEmbed/lib';
import useEventCallback from '../../../utils/useEventCallback';
import { PageDetails, ReportDetails, WorkspaceDetails } from '../api';
import { useBiReportContext, usePowerBiApi } from '../BiReportContext';
import { useRequestCache } from '../../bi/PowerBiRestEmbed/ApiRequestCacheContext';
import { PowerBIBookmark } from '../bookmarks/lib';

export type RecentItem = {
  workspaceId: string;
  reportId: string;
  pageName?: string;
  // This is for use with PowerBI managed bookmarks (will soon be deprecated)
  bookmarkName?: string;
  // Used for Crisp managed bookmarks
  bookmarkId?: string;
};

export type ReportSelection = Partial<RecentItem>;

export type ReportPageSelection = {
  reportId: string;
  reportType?: ReportType;
  embedUrl?: string;
  pageName?: string;
  bookmarkId?: string;
};

export type ReportMenuSelection = ReportSelection & {
  embedUrl?: string;
  reportType?: ReportType;
};

export const reportSelectionEqualsWithoutBookmarkName = (
  a: ReportSelection,
  b: ReportSelection,
): boolean => {
  return (
    a.workspaceId === b.workspaceId &&
    a.reportId === b.reportId &&
    a.pageName === b.pageName &&
    a.bookmarkId === b.bookmarkId
  );
};

export const reportSelectionEqualsWithoutBookmark = (
  a: ReportSelection,
  b: ReportSelection,
): boolean => {
  return a.workspaceId === b.workspaceId && a.reportId === b.reportId && a.pageName === b.pageName;
};

export const reportSelectionEquals = (a: ReportSelection, b: ReportSelection): boolean => {
  return reportSelectionEqualsWithoutBookmarkName(a, b) && a.bookmarkName === b.bookmarkName;
};

export const isHiddenPageDisplayName = (pageDisplayName: string): boolean =>
  pageDisplayName.toLowerCase().endsWith('[hidden]') || pageDisplayName.startsWith('H_');

export const isHiddenPage = (page: Pick<PageDetails, 'displayName'>) =>
  isHiddenPageDisplayName(page.displayName);

/**
 * Since a ReportSelection does not include an embedUrl, we need to fetch the report to seek the embedUrl such that
 * the report can be loaded. This hook will first retrieve the report by id and then trigger a menu selection update.
 * @param setSelection
 */
export const useSetMenuSelectionForReportSelection = (
  setSelection: React.Dispatch<SetStateAction<Maybe<ReportMenuSelection>>>,
) => {
  const [getReport, { data: reportDetails }] = usePowerBiApi().useGetReportLazy();
  const [updateSelectionWhenReportIsAvailable, setUpdateSelectionWhenReportIsAvailable] =
    useState<Maybe<ReportSelection>>(undefined);

  // This effect will be executed in the event that a report query fails initially, but it later becomes available.
  // (The query is automatically retried when the access token is refreshed).
  useEffect(() => {
    if (updateSelectionWhenReportIsAvailable && reportDetails) {
      setSelection({
        ...updateSelectionWhenReportIsAvailable,
        reportType: reportDetails.reportType,
        embedUrl: reportDetails.embedUrl,
      });
      setUpdateSelectionWhenReportIsAvailable(undefined);
    }
  }, [updateSelectionWhenReportIsAvailable, reportDetails, setSelection]);

  return useEventCallback(async (nextSelection: ReportSelection) => {
    const { reportId, workspaceId } = nextSelection;
    if (reportId && workspaceId) {
      const report = await getReport({ reportId, workspaceId: workspaceId });
      if (report) {
        setSelection({
          ...nextSelection,
          embedUrl: report.embedUrl,
          reportType: report.reportType,
        });
      } else {
        setUpdateSelectionWhenReportIsAvailable(nextSelection);
      }
    } else {
      setSelection(nextSelection);
    }
  });
};

export const compareReportPages = (p1: PageDetails, p2: PageDetails) => p1.order - p2.order;
export const compareBookmarks = (b1: PowerBIBookmark, b2: PowerBIBookmark) =>
  b1.name.localeCompare(b2.name);

const EmptySelection: ReportSelection = {
  workspaceId: undefined,
  reportId: undefined,
  pageName: undefined,
  bookmarkName: undefined,
  bookmarkId: undefined,
};

const findSelectionElementFactory = (get: <T>(path: string) => Maybe<T>) => ({
  findGroup: (groupId: string): Maybe<WorkspaceDetails> => {
    return get<WorkspaceDetails[]>(PowerBiRequest.groups())?.find(g => g.id === groupId);
  },
  findReport: (groupId: string, reportId: string): Maybe<ReportDetails> => {
    return get<ReportDetails[]>(PowerBiRequest.reports(groupId))?.find(r => r.id === reportId);
  },
  findPage: (reportId: string, pageName: string): Maybe<PageDetails> =>
    get<PageDetails[]>(PowerBiRequest.pages(reportId))?.find(page => page.name === pageName),
});

const translateEventAttributes = (attributes: Record<string, string>) => {
  return Object.fromEntries(
    Object.entries(attributes).map(([key, value]) => {
      if (key === 'groupId') {
        return ['moduleId', value];
      } else if (key === 'groupName') {
        return ['moduleName', getWorkspaceFriendlyName(value)];
      }

      return [key, value];
    }),
  );
};

const SelectionEventTypes = ['selection', 'workspace', 'report', 'page', 'bookmark'] as const;
export type SelectionEventType = (typeof SelectionEventTypes)[number];

const KeyToSelectionEvent: Record<keyof ReportSelection, SelectionEventType> = {
  workspaceId: 'workspace',
  reportId: 'report',
  pageName: 'page',
  bookmarkName: 'bookmark',
  bookmarkId: 'bookmark',
};

export type SelectionChangeFn = (
  eventType: SelectionEventType,
  attributes: AnyObject,
  selection: Maybe<ReportSelection>,
) => void;

export const useReportSelectionListener = (
  selection: Maybe<ReportSelection>,
  onChange: SelectionChangeFn,
) => {
  // The account is just to ensure we re-send certain events when account is changed
  const { account } = useBiReportContext();
  const lastReportSelectionRef = useRef<Maybe<ReportSelection>>();
  const { get } = useRequestCache();

  const finder = useMemo(() => findSelectionElementFactory(get), [get]);
  const findElementNamesForSelection = useEventCallback(
    ({ workspaceId, reportId, pageName }: ReportSelection) =>
      Object.fromEntries(
        Object.entries({
          groupName: (workspaceId && finder.findGroup(workspaceId)?.name) || undefined,
          reportName:
            (reportId && workspaceId && finder.findReport(workspaceId, reportId)?.name) ||
            undefined,
          pageName:
            (reportId && pageName && finder.findPage(reportId, pageName)?.displayName) || undefined,
        }).filter(e => e[1]),
      ),
  );

  useEffect(() => {
    if (selection) {
      if (!lastReportSelectionRef.current) {
        const names = findElementNamesForSelection(selection);
        onChange('selection', translateEventAttributes({ ...selection, ...names }), selection);

        Object.keys(EmptySelection).forEach(key => {
          if (selection[key]) {
            onChange(
              KeyToSelectionEvent[key],
              translateEventAttributes({
                ..._.pick(selection, key),
                ...names,
              }),
              selection,
            );
          }
        });
      } else if (
        Object.keys(selection).length > 0 &&
        !reportSelectionEquals(selection, lastReportSelectionRef.current)
      ) {
        const names = findElementNamesForSelection(selection);
        onChange('selection', translateEventAttributes({ ...selection, ...names }), selection);
        Object.keys(EmptySelection).forEach(key => {
          if (selection[key] !== lastReportSelectionRef.current?.[key]) {
            onChange(
              KeyToSelectionEvent[key],
              translateEventAttributes({
                ..._.pick(selection, key),
                ...names,
              }),
              selection,
            );
          }
        });
      }
    }

    lastReportSelectionRef.current = selection;
  }, [selection, account, findElementNamesForSelection, onChange]);
};

export const MenuTabs = ['All', 'Pages', 'Bookmarks'] as const;
export type MenuTab = (typeof MenuTabs)[number];
