import pathToRegexp from 'path-to-regexp';
import { maybe, Maybe } from '@tellurian/ts-utils';
import { RouteDeclaration, RouteIdToRoute } from '../../components/routing/lib';
import PartnerHomePage from '../../partner/pages/HomePage';
import PartnerSignInPage from '../../partner/pages/SignInPage';
import PartnerAnalyticsPage from '../pages/AnalyticsPage';
import PartnerLandingPage from '../pages/LandingPage';
import { PartnerApplication } from '../../components/lib/Account';

export const Routes = [
  {
    id: 'PartnerLanding',
    path: '/p',
    Render: PartnerLandingPage,
  },
  {
    id: 'PartnerHome',
    path: '/p/:partnerApplication',
    Render: PartnerHomePage,
    requireAuth: true,
  },
  {
    id: 'PartnerAnalytics',
    path: '/p/:partnerApplication/reports',
    Render: PartnerAnalyticsPage,
    requireAuth: true,
    //requireFeatureFlag: KnownFlags.DotMiniSite,
  },
  {
    id: 'PartnerSignIn',
    path: '/p/signin',
    Render: PartnerSignInPage,
  },
  {
    id: 'PartnerAuthCallback',
    path: '/p/signin/callback',
    Render: PartnerSignInPage,
  },
] as const satisfies RouteDeclaration[];

export type RouteId = (typeof Routes)[number]['id'];

export const RouteById = Routes.reduce<RouteIdToRoute<RouteId>>(
  (acc, r) => ({ ...acc, [r.id]: r }),
  {} as RouteIdToRoute<RouteId>,
);

const PartnerHomePathRegExp = pathToRegexp(RouteById['PartnerHome'].path);

export const getPartnerApplicationFromPathname = (pathname: string): Maybe<PartnerApplication> => {
  return maybe(PartnerHomePathRegExp.exec(pathname)?.[1]) as Maybe<PartnerApplication>;
};
