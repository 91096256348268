import { useEffect, useMemo, useState } from 'react';
import { Maybe, maybe } from '@tellurian/ts-utils';
import { useHistory } from 'react-router';
import { VisualDescriptor } from 'powerbi-client';
import useEventCallback from '../../utils/useEventCallback';
import { getWorkspaceFriendlyName } from '../bi/PowerBiRestEmbed/lib';
import { Account } from '../lib/Account';
import { getNamespaceForPartnerAccount } from '../../partner/lib/PartnerContext';
import { useGetBookmark } from '../bi/common/BookmarkDataContext';
import { useBiReportContext, usePowerBiApi } from './BiReportContext';
import { PageDetails, ReportDetails, WorkspaceDetails } from './api';
import {
  ReportSelection,
  reportSelectionEquals,
  reportSelectionEqualsWithoutBookmarkName,
} from './ReportMenu/lib';
import { RecentReport, UseRecentReports } from './RecentReports/useRecentReports';
import { PowerBIBookmark } from './bookmarks/lib';
import { useBookmarkEventListener } from './ListenerProvider';

export const getNamespaceForAccount = (account: Account) =>
  account.type === 'platform' ? account.accountId : getNamespaceForPartnerAccount(account);

export const useAccountNamespace = () => {
  const account = useBiReportContext().account;
  return useMemo(() => getNamespaceForAccount(account), [account]);
};

export type BiPageConfig = {
  workspace?: Pick<WorkspaceDetails, 'id'>;
  report?: Pick<ReportDetails, 'id'>;
  page?: Pick<PageDetails, 'name'>;
  bookmarkName?: string;
  bookmark?: Pick<PowerBIBookmark, 'id'>;
};

export type GetBiPageUrl = (config: BiPageConfig) => string;

export const getSearchParamsForConfig = (config: BiPageConfig) => {
  const { workspace, report, page, bookmarkName, bookmark } = config;
  const params = {};
  if (workspace) {
    params[SearchParamNames.Group] = workspace.id;
    if (report) {
      params[SearchParamNames.Report] = report.id;
      if (page) {
        params[SearchParamNames.Page] = page.name;
        if (bookmark) {
          params[SearchParamNames.Bookmark] = bookmark.id;
        } else if (bookmarkName) {
          params[SearchParamNames.NativeBookmark] = bookmarkName;
        }
      }
    }
  }

  return new URLSearchParams(params).toString();
};

// This should be temporary until we implement platform and partner account features for this
type FeaturesAvailableForAccountType = {
  search: boolean;
  settings: boolean;
};

export const useFeaturesEnabledForAccount = (): FeaturesAvailableForAccountType => {
  const account = useBiReportContext().account;
  if (account.type === 'partner') {
    return { search: false, settings: false };
  }

  return { search: true, settings: true };
};

export type RenderHomeProps = {
  recentReports: Omit<UseRecentReports, 'clear'> & {
    onReportSelected?: (recentReport: RecentReport) => void;
    onClear: UseRecentReports['clear'];
  };
};

export type ReportSelectionEntities = {
  workspace?: { id: string; name: string; displayName: string };
  report?: { id: string; name: string; datasetId: string };
  page?: { name: string; displayName: string };
  bookmark?: { id: string; name: string };
};

export enum SearchParamNames {
  Group = 'groupId',
  Report = 'reportId',
  Page = 'page',
  NativeBookmark = 'bookmark',
  Bookmark = 'bookmarkId',
}

const getReportSelectionFromUrlParams = (): Maybe<ReportSelection> => {
  let reportRef: Maybe<ReportSelection> = undefined;
  const urlParams = new URLSearchParams(window.location.search);
  const groupId = urlParams.get(SearchParamNames.Group);
  if (groupId) {
    reportRef = { workspaceId: groupId };
  }
  const reportId = urlParams.get(SearchParamNames.Report);
  if (reportId) {
    reportRef = { ...reportRef, reportId };
    const pageName = urlParams.get(SearchParamNames.Page) || undefined;
    if (pageName) {
      reportRef = { ...reportRef, pageName };
      const bookmarkId = urlParams.get(SearchParamNames.Bookmark) || undefined;
      if (bookmarkId) {
        reportRef = { ...reportRef, bookmarkId };
      } else {
        const bookmarkName = urlParams.get(SearchParamNames.NativeBookmark) || undefined;
        if (bookmarkName) {
          reportRef = { ...reportRef, bookmarkName };
        }
      }
    }
  }

  return reportRef;
};

const applySelectionToUrlParams = ({
  workspaceId,
  pageName,
  reportId,
  bookmarkName,
  bookmarkId,
}: ReportSelection) => {
  const params = new URLSearchParams(window.location.search);
  if (workspaceId) {
    params.set(SearchParamNames.Group, workspaceId);
  } else {
    params.delete(SearchParamNames.Group);
  }
  if (reportId) {
    params.set(SearchParamNames.Report, reportId);
  } else {
    params.delete(SearchParamNames.Report);
  }
  if (pageName) {
    params.set(SearchParamNames.Page, pageName);
  } else {
    params.delete(SearchParamNames.Page);
  }
  if (bookmarkId) {
    params.set(SearchParamNames.Bookmark, bookmarkId);
  } else {
    params.delete(SearchParamNames.Bookmark);
  }
  if (bookmarkName) {
    params.set(SearchParamNames.NativeBookmark, bookmarkName);
  } else {
    params.delete(SearchParamNames.NativeBookmark);
  }

  return params;
};

type UseReportSelectionHistorySyncParams = {
  selection?: ReportSelection;
  setSelection: (nextSelection: ReportSelection) => Promise<void>;
};

export const useReportSelectionSync = ({
  selection,
  setSelection,
}: UseReportSelectionHistorySyncParams) => {
  const [isInitComplete, setIsInitComplete] = useState(false);
  useEffect(() => {
    const initialSelection = getReportSelectionFromUrlParams();
    if (initialSelection) {
      setSelection(initialSelection).then(() => setIsInitComplete(true));
    } else {
      setIsInitComplete(true);
    }
  }, [setSelection]);

  useEffect(() => {
    if (selection) {
      const params = new URLSearchParams(window.location.search);
      const applyNextState = () => {
        const query = applySelectionToUrlParams(selection).toString();
        window.history.pushState({}, '', window.location.pathname + query ? `?${query}` : '');
      };

      const currentReportId = params.get(SearchParamNames.Report);
      if (currentReportId) {
        const currentPageName = params.get(SearchParamNames.Page);
        const currentBookmarkName = params.get(SearchParamNames.NativeBookmark);
        const currentBookmarkId = params.get(SearchParamNames.Bookmark);
        if (
          !reportSelectionEquals(selection, {
            reportId: currentReportId,
            pageName: maybe(currentPageName),
            bookmarkName: maybe(currentBookmarkName),
            bookmarkId: maybe(currentBookmarkId),
          })
        ) {
          applyNextState();
        }
      } else {
        applyNextState();
      }
    }
  }, [selection]);

  const history = useHistory();
  useEffect(() => {
    const historyChangeHandler = () => {
      const selection = getReportSelectionFromUrlParams();
      if (selection) {
        setSelection(selection);
      }
    };
    return history.listen(historyChangeHandler);
  }, [history, setSelection]);

  return isInitComplete;
};

const getReportSelectionFromEntities = ({
  workspace,
  report,
  page,
  bookmark,
}: ReportSelectionEntities): ReportSelection => ({
  workspaceId: workspace?.id,
  reportId: report?.id,
  pageName: page?.name,
  bookmarkId: bookmark?.id,
  bookmarkName: undefined,
});

const EmptySelection: ReportSelection = {
  workspaceId: undefined,
  reportId: undefined,
  pageName: undefined,
  bookmarkId: undefined,
};

const EmptyReportSelectionEntities: ReportSelectionEntities = {
  workspace: undefined,
  report: undefined,
  page: undefined,
  bookmark: undefined,
};

type UseReportSelectionEntitiesParams = {
  selection?: ReportSelection;
  getSelectionEntities: ReturnType<typeof useGetReportSelectionEntitiesLazy>;
};

export type GetReportSelectionEntitiesParams = {
  selection?: ReportSelection;
  currentEntities?: ReportSelectionEntities;
};

export const useGetReportSelectionEntitiesLazy = (workspaces: WorkspaceDetails[]) => {
  const getBookmark = useGetBookmark();

  const [getReports] = usePowerBiApi().useGetWorkspaceReportsLazy({
    workspaceId: '',
    options: { fetchPolicy: 'cache-first' },
  });
  const [getPages] = usePowerBiApi().useGetPagesLazy({
    workspaceId: '',
    reportId: '',
    options: { fetchPolicy: 'cache-first' },
  });

  return useEventCallback(
    async ({ selection, currentEntities }: GetReportSelectionEntitiesParams) => {
      if (!selection) {
        return EmptyReportSelectionEntities;
      }

      const { workspaceId, reportId, pageName, bookmarkId } = selection;
      const nextSelectionEntities: ReportSelectionEntities = {};
      if (workspaceId) {
        if (workspaceId === currentEntities?.workspace?.id) {
          nextSelectionEntities.workspace = currentEntities?.workspace;
        } else {
          const workspaceDetails = workspaces.find(w => w.id === workspaceId);
          if (workspaceDetails) {
            nextSelectionEntities.workspace = {
              ...workspaceDetails,
              displayName: getWorkspaceFriendlyName(workspaceDetails.name),
            };
          }
        }

        if (reportId) {
          if (nextSelectionEntities.workspace) {
            if (reportId === currentEntities?.report?.id) {
              nextSelectionEntities.report = currentEntities?.report;
            } else {
              const reports = await getReports({ workspaceId: workspaceId });
              if (reports) {
                const report = reports.find(r => r.id === reportId);
                if (report) {
                  nextSelectionEntities.report = report;
                }
              }
            }
          }

          if (nextSelectionEntities.report) {
            if (pageName === currentEntities?.page?.name) {
              nextSelectionEntities.page = currentEntities?.page;
            } else {
              const pages = await getPages({ workspaceId, reportId });
              if (pages) {
                const page = pages.find(p => p.name === pageName);
                if (page) {
                  nextSelectionEntities.page = page;
                }
              }
            }
          }
        }
      }

      if (nextSelectionEntities.page) {
        if (bookmarkId === currentEntities?.bookmark?.id) {
          nextSelectionEntities.bookmark = currentEntities?.bookmark;
        } else {
          if (bookmarkId && workspaceId) {
            const bookmark = await getBookmark({
              bookmarkId,
              workspaceId,
            });
            if (bookmark) {
              nextSelectionEntities.bookmark = bookmark;
            }
          }
        }
      }

      return nextSelectionEntities;
    },
  );
};

export const useReportSelectionEntities = ({
  selection,
  getSelectionEntities,
}: UseReportSelectionEntitiesParams): ReportSelectionEntities => {
  const [reportSelectionEntities, setReportSelectionEntities] = useState<ReportSelectionEntities>(
    EmptyReportSelectionEntities,
  );
  const updateReportSelectionEntities = useEventCallback(
    async (currentEntities: ReportSelectionEntities = reportSelectionEntities) => {
      setReportSelectionEntities(
        await getSelectionEntities({
          selection,
          currentEntities,
        }),
      );
    },
  );

  const { workspaceId, reportId, pageName, bookmarkId } = selection || EmptySelection;
  const shouldSkipUpdate = reportSelectionEqualsWithoutBookmarkName(
    { workspaceId, reportId, pageName, bookmarkId },
    getReportSelectionFromEntities(reportSelectionEntities),
  );

  useEffect(() => {
    if (!shouldSkipUpdate) {
      updateReportSelectionEntities();
    }
  }, [shouldSkipUpdate, updateReportSelectionEntities]);

  useBookmarkEventListener(({ bookmark, type }) => {
    if (type === 'bookmarkUpdated' && bookmark.id === reportSelectionEntities.bookmark?.id) {
      // Update entities and force refetch the bookmark
      updateReportSelectionEntities({ ...reportSelectionEntities, bookmark: undefined });
    }
  });

  return reportSelectionEntities;
};

export const bookmarkMatchesReportSelection = (
  reportSelection: Maybe<ReportSelection>,
  bookmark: PowerBIBookmark,
): boolean => {
  if (reportSelection) {
    const { workspaceId, reportId, pageName } = reportSelection;
    if (
      bookmark.reportId === reportId &&
      bookmark.workspaceId === workspaceId &&
      bookmark.pageName === pageName
    ) {
      return true;
    }
  }

  return false;
};

type SlicerProperties = {
  isVisible: boolean;
  isLocked: boolean;
};

export const getSlicerProperties = ({ title }: VisualDescriptor) => {
  const base: SlicerProperties = { isVisible: false, isLocked: false };
  if (title.endsWith('_isvisible_locked') || title.endsWith('_locked_isvisible')) {
    return { isVisible: true, isLocked: true };
  }

  if (title.endsWith('_isvisible')) {
    return { ...base, isVisible: true };
  }

  if (title.endsWith('_locked')) {
    return { ...base, isLocked: true };
  }

  return base;
};
