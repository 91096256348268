import React, { useContext, useEffect } from 'react';
import listenerManager, { ListenerManager } from '../../utils/listenerManager';
import { FCC } from '../../../../utils/types';
import useSingleton from '../../../../utils/useSingleton';
import useEventCallback from '../../utils/useEventCallback';
import { PowerBIBookmark } from './bookmarks/lib';

export type PowerBiBookmarkEvent = {
  bookmark: PowerBIBookmark;
  type: 'bookmarkAdded' | 'bookmarkDeleted' | 'bookmarkUpdated' | 'cacheUpdated';
};

type ListenerContextInterface = {
  bookmarkEventListeners: ListenerManager<PowerBiBookmarkEvent>;
};

const ListenerContext = React.createContext<ListenerContextInterface>({
  bookmarkEventListeners: listenerManager<PowerBiBookmarkEvent>(),
});

const ListenerProvider: FCC = ({ children }) => {
  const bookmarkEventListeners = useSingleton(() => listenerManager<PowerBiBookmarkEvent>());
  return (
    <ListenerContext.Provider value={{ bookmarkEventListeners }}>
      {children}
    </ListenerContext.Provider>
  );
};

export default ListenerProvider;

export const useListenerContext = () => useContext(ListenerContext);

export const useBookmarkEventListener = (listener: (bookmark: PowerBiBookmarkEvent) => void) => {
  const listenerToUse = useEventCallback(listener);
  const listeners = useListenerContext().bookmarkEventListeners;
  useEffect(() => {
    return listeners.addListener(listenerToUse);
  }, [listeners, listenerToUse]);
};
