import React from 'react';
import DotLogoPng from '../../../../assets/dot-logo.png';
import { PartnerApplication } from '../../components/lib/Account';

type PartnerLogoVariant = 'navrail' | 'banner';

const NavrailLogoStyles: Partial<Record<PartnerApplication, object>> = {
  [PartnerApplication.Dot]: {
    width: '52px',
    height: '52px',
  },
};

const BannerLogoStyles: Partial<Record<PartnerApplication, object>> = {
  [PartnerApplication.Dot]: {
    width: '100px',
    height: '100px',
  },
};

const LogoSrc: Record<PartnerApplication, string> = {
  [PartnerApplication.Dot]: DotLogoPng,
};

type PartnerLogoProps = {
  partnerType: PartnerApplication;
  variant: PartnerLogoVariant;
};

const PartnerLogo: React.FC<PartnerLogoProps> = ({ variant, partnerType }) => {
  const style = (variant === 'navrail' ? NavrailLogoStyles : BannerLogoStyles)[partnerType];
  return <img src={LogoSrc[partnerType]} alt="logo" style={style} />;
};

export default PartnerLogo;
