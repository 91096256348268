import React from 'react';
import Title from '../../components/Title';
import { KeyboardShortcutSpec } from '../../../keyboardSupport/lib';
import RadioGroupField from '../../components/RadioGroupField';
import Modal from '../../components/Modal';
import Notification from '../../components/Notification';
import { NotificationType } from '../../components/lib/notifications/lib';
import CheckboxField from '../../components/CheckboxField';
import { ThemeOption, useUserPreferencesContext } from './UserPreferencesProvider';

const FullSupportThemes: ThemeOption[] = [
  'Crisp Lettuce',
  'Advantage',
  'High Impact',
  'Dot',
  'Instacart',
];
const PartialSupportThemes: ThemeOption[] = ['UNFI', 'Rema'];

export const UserPreferencesShortcutSpec: KeyboardShortcutSpec = {
  keyCombo: { code: 'Comma', shift: true, meta: true },
  description: 'Open preferences',
};

type UserPreferencesEditorProps = {
  isActive?: boolean;
  onClose: () => void;
};

const UserPreferencesEditor: React.FC<UserPreferencesEditorProps> = ({ isActive, onClose }) => {
  const { preferences, setTheme, updatePreferences } = useUserPreferencesContext();

  const powerBiPreferences = preferences.labs.powerBi;

  return isActive ? (
    <Modal size="S" title="Preferences" onCloseClick={onClose}>
      <Title>Theme</Title>
      <RadioGroupField
        radioItems={FullSupportThemes}
        value={preferences.general.theme}
        onChange={setTheme}
      />
      <CheckboxField
        label="Override account bound theme"
        description="If checked, the preferred theme will take precedence over the theme associated with a particular account."
        checked={preferences.general.overrideAccountTheme}
        onChange={isChecked => updatePreferences({ general: { overrideAccountTheme: isChecked } })}
      />
      <Title size="M">Application themes</Title>
      <RadioGroupField
        radioItems={PartialSupportThemes}
        value={preferences.general.theme}
        onChange={setTheme}
      />
      <Notification
        notificationType={NotificationType.Info}
        content={
          <div>
            Application themes define styles for the subset of features pertaining to the respective
            application. These are not applicable to our entire suite of components and are
            primarily present to help development.
          </div>
        }
      />
      <>
        <Title>Labs</Title>
        <Title size="M">PowerBI</Title>
        <CheckboxField
          label="Enable UX v2 (under construction)"
          checked={powerBiPreferences.ux2}
          onChange={isChecked =>
            updatePreferences({
              labs: { powerBi: { ...powerBiPreferences, ux2: isChecked } },
            })
          }
        />
        <CheckboxField
          label="Enable UX v2 PowerBI report theme"
          checked={powerBiPreferences.ux2ReportThemeEnabled}
          onChange={isChecked =>
            updatePreferences({
              labs: { powerBi: { ...powerBiPreferences, ux2ReportThemeEnabled: isChecked } },
            })
          }
        />
      </>
    </Modal>
  ) : null;
};

export default UserPreferencesEditor;
