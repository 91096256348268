import React, { createContext, useContext, useMemo } from 'react';
import { ConnectorApplication } from '../../../../generated/graphql';
import { FCC } from '../../../../utils/types';
import {
  PartnerAccount,
  partnerAccount,
  PartnerAccountWithType,
  PartnerApplication,
} from '../../components/lib/Account';
import { PartnerTypeToConnectorApplication } from './index';

type PartnerContextInterface = {
  partnerAccount: PartnerAccountWithType;
  // Some partners may be associated with a connector application. This helps us
  // render a default logo for the partner workspaces.
  partnerConnectorApplication?: ConnectorApplication;
};

export const PartnerContext = createContext<PartnerContextInterface>({
  partnerAccount: partnerAccount('', PartnerApplication.Dot),
  partnerConnectorApplication: undefined,
});

type PartnerContextProviderProps = Pick<PartnerContextInterface, 'partnerAccount'>;

const PartnerContextProvider: FCC<PartnerContextProviderProps> = ({ children, partnerAccount }) => {
  return (
    <PartnerContext.Provider
      value={{
        partnerAccount,
        partnerConnectorApplication:
          PartnerTypeToConnectorApplication[partnerAccount.partnerApplication],
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContextProvider;

export const usePartnerContext = () => useContext(PartnerContext);
export const usePartnerAccount = () => useContext(PartnerContext).partnerAccount;

export const getNamespaceForPartnerAccount = (account: PartnerAccount) =>
  `p/${account.partnerAccountId}`;

// This has to be included here and not lib to avoid a weird circular dependency
export const usePartnerAccountNamespace = () => {
  const account = usePartnerAccount();
  return useMemo(() => getNamespaceForPartnerAccount(account), [account]);
};
