import React from 'react';
import { RouteComponentProps } from 'react-router';
import AccountDetails from '../../../AccountManager/AccountDetails';
import { AccountDetailsTab } from '../routing/Tabs';
import GlobalAdminTemplate from '../templates/GlobalAdminTemplate';
import { lazyWithRetry } from '../../../lib';

const RenderAccountSettings = lazyWithRetry<typeof AccountDetails>(
  () => import(/* webpackChunkName: "accountSettings" */ '../../../AccountManager/AccountDetails'),
);

type MatchParams = {
  accountId: string;
  tab?: AccountDetailsTab;
  identifier?: string;
};

export default function AccountSettingsPage({
  match: {
    params: { accountId, tab, identifier },
  },
}: RouteComponentProps<MatchParams>) {
  return (
    <GlobalAdminTemplate>
      <RenderAccountSettings accountId={accountId} tabId={tab} identifier={identifier} />
    </GlobalAdminTemplate>
  );
}
