import React from 'react';
import TermsOfServiceVersions from '../components/TermsOfServiceVersions';
import GlobalAdminTemplate from '../components/lettuce/crisp/templates/GlobalAdminTemplate';

export default function TermsOfServiceVersionsPage() {
  return (
    <GlobalAdminTemplate requireGlobalAdmin={true}>
      <TermsOfServiceVersions />
    </GlobalAdminTemplate>
  );
}
