import React, { ReactElement, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { AnyObject } from '@tellurian/ts-utils';
import { ExternalLinkTarget } from '../ExternalLink';
import { useMenuEventListener } from '../../crisp/lib';
import usePopoverSubMenu, { UsePopoverSubMenuParams } from './usePopoverSubMenu';
import SubMenuPopover, { SubMenuPopoverProps } from './SubMenuPopover';
import PopoverActionContextProvider from './PopoverActionContext';
import { useIsCompactNavRail } from './NavRailContextProvider';
import style from './menuItems.module.css';

type MenuItemContentProps = {
  RenderIcon: React.FC;
  label: string;
};

const MenuItemContent: React.FC<MenuItemContentProps> = React.memo(function MenuItemContent({
  RenderIcon,
  label,
}) {
  const isCompact = useIsCompactNavRail();
  return (
    <>
      <div className={style.iconContainer} aria-hidden={true}>
        <RenderIcon />
      </div>
      {!isCompact && <div className={style.label}>{label}</div>}
    </>
  );
});

function WithLi<T extends AnyObject>(Component: React.FC<T>) {
  // eslint-disable-next-line react/display-name
  return (props: T) => (
    <li>
      <Component {...props} />
    </li>
  );
}

type MenuItemProps = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  path: string;
  isSelected?: boolean;
  onMouseDown?: () => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  path,
  Icon,
  label,
  onMouseDown,
  isSelected = false,
}) => {
  return (
    <Link
      to={path}
      className={clsx(style.item, {
        [style.selected]: isSelected,
        [style.compact]: useIsCompactNavRail(),
      })}
      onMouseDown={onMouseDown}
      role="menuitem"
    >
      <MenuItemContent label={label} RenderIcon={Icon} />
    </Link>
  );
};

export const MenuListItem = WithLi(MenuItem);

type ExternalLinkMenuItemProps = Pick<MenuItemProps, 'Icon' | 'label'> & {
  href: string;
  target?: ExternalLinkTarget;
  onClick?: () => void;
};

export const ExternalLinkMenuItem: React.FC<ExternalLinkMenuItemProps> = React.memo(
  function ExternalLinkMenuItem({
    label,
    href,
    Icon,
    target = ExternalLinkTarget.newTab,
    onClick,
  }) {
    return (
      <a
        href={href}
        target={target}
        className={clsx(style.item, { [style.compact]: useIsCompactNavRail() })}
        onClick={onClick}
        role="menuitem"
      >
        <MenuItemContent label={label} RenderIcon={Icon} />
      </a>
    );
  },
);

export const ExternalLinkMenuListItem = WithLi(ExternalLinkMenuItem);

type MenuItemWithPopoverSubMenuProps<T extends AnyObject = AnyObject> = T &
  Pick<MenuItemContentProps, 'RenderIcon' | 'label'> &
  Pick<SubMenuPopoverProps, 'type'> &
  Pick<MenuItemProps, 'onMouseDown' | 'isSelected'> & {
    onClick?: () => void;
    RenderContent: React.FC<T>;
    subMenuType?: SubMenuPopoverProps['type'];
    // The only purpose of this property is to allow toggling of the submenu from a parent component as a side effect.
    showSubMenu?: boolean;
    ariaLabel?: string;
  } & Pick<UsePopoverSubMenuParams, 'id' | 'placement'>;

export function MenuItemWithPopoverSubMenu<T extends AnyObject = AnyObject>({
  RenderIcon,
  label,
  RenderContent,
  id,
  placement,
  subMenuType,
  showSubMenu = false,
  ariaLabel,
  onClick: onClickProp,
  ...renderContentProps
}: MenuItemWithPopoverSubMenuProps<T>) {
  const {
    popoverProps,
    isVisible,
    toggle,
    setReferenceElement,
    referenceElementProps,
    show,
    hide,
    update,
  } = usePopoverSubMenu<HTMLElement>({
    placement,
    isInitiallyVisible: showSubMenu,
    id,
    ariaLabel,
  });
  const onClick = useCallback(() => {
    toggle();
    onClickProp?.();
  }, [onClickProp, toggle]);

  useEffect(() => {
    showSubMenu ? show() : hide();
  }, [showSubMenu, show, hide]);

  useMenuEventListener(id, isOpen => (isOpen ? show() : hide()));

  return (
    <>
      <button
        type="button"
        onClick={onClick}
        ref={setReferenceElement}
        className={clsx(style.item, {
          [style.focused]: isVisible,
          [style.compact]: useIsCompactNavRail(),
        })}
        role="menuitem"
        {...referenceElementProps}
      >
        <MenuItemContent label={label} RenderIcon={RenderIcon} />
      </button>
      {isVisible && (
        <PopoverActionContextProvider show={show} hide={hide} onContentChanged={update}>
          <SubMenuPopover {...popoverProps} type={subMenuType}>
            <RenderContent {...(renderContentProps as unknown as T)} />
          </SubMenuPopover>
        </PopoverActionContextProvider>
      )}
    </>
  );
}

export const MenuListItemWithPopoverSubMenu = WithLi(MenuItemWithPopoverSubMenu) as <
  T extends AnyObject,
>(
  props: MenuItemWithPopoverSubMenuProps<T>,
) => ReactElement;
