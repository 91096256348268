import React, { useMemo } from 'react';
import { ReactComponent as IconKnowledgeBase } from '../../../assets/icons/lettuce/unfi/knowledgebase.svg';
import { ReactComponent as IconAcademy } from '../../../assets/icons/lettuce/unfi/academy.svg';
import { ReactComponent as IconUpdates } from '../../../assets/icons/lettuce/unfi/updates.svg';
import { ReactComponent as IconDataDictionary } from '../../../assets/icons/lettuce/data-dictionary.svg';
import { ExternalLinkTarget } from '../components/ExternalLink';
import { Resource } from '../components/ResourceCards/lib';
import { Feature } from '../../../utils/features';
import useIsFeatureFlagEnabled from './featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from './featureFlags/KnownFlags';

export const KnowledgeBaseResource = (url: string | undefined) => ({
  title: 'Knowledge Base',
  subTitle: 'Dive into our detailed articles to solve your queries.',
  buttonText: 'Explore Now',
  url,
  icon: <IconKnowledgeBase />,
  target: ExternalLinkTarget.knowledgeBase,
});

export const AcademyResource = (url: string | undefined) => ({
  title: 'Academy',
  subTitle: 'Master our product with comprehensive tutorials.',
  buttonText: 'Start Learning',
  url,
  icon: <IconAcademy />,
  target: ExternalLinkTarget.academy,
});

export const RecentUpdatesResource = (url: string | undefined) => ({
  title: 'Recent Updates',
  subTitle: 'Stay up-to-date with our latest features and improvements.',
  buttonText: 'See Updates',
  url,
  icon: <IconUpdates />,
  target: ExternalLinkTarget.knowledgeBase,
});

type UseResourcesParams = {
  onDataDictionaryOpen?: () => void;
};

export const useResources = (
  resourcesBase: Resource[],
  params?: UseResourcesParams,
): Resource[] => {
  const onDataDictionaryOpen = params?.onDataDictionaryOpen;
  const isDataDictionaryEnabled = useIsFeatureFlagEnabled(KnownFlags.DataDictionary);

  return useMemo(() => {
    if (isDataDictionaryEnabled && onDataDictionaryOpen) {
      return [
        ...resourcesBase,
        {
          title: 'Data Dictionary',
          subTitle: 'Look up dimensions or measures for a detailed explanation.',
          buttonText: 'Launch Data Dictionary',
          icon: <IconDataDictionary />,
          featureId: Feature.RetailAnalyticsDataDictionaryOpened,
          onClick: onDataDictionaryOpen,
        },
      ];
    }

    return resourcesBase;
  }, [resourcesBase, isDataDictionaryEnabled, onDataDictionaryOpen]);
};
