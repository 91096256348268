import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ExternalLinkTarget } from '../ExternalLink';
import Card from '../Card';
import Title from '../Title';
import { Resource } from './lib';
import style from './index.module.css';

type ResourcesCardProps = {
  icon: ReactNode;
  title: string;
  subTitle: string;
  buttonText: string;
  to?: string;
  href?: string;
  target?: ExternalLinkTarget;
  onClick?: () => void;
};

const ResourcesCard: React.FC<ResourcesCardProps> = ({
  icon,
  title,
  subTitle,
  buttonText,
  to,
  href,
  target,
  onClick,
}) => {
  return (
    <Card to={to} href={href} target={target} className={style.resourceCard} onClick={onClick}>
      <div className={style.iconContainer}>{icon}</div>
      <div className={style.content}>
        <Title className={style.title}>{title}</Title>
        <div className="subtitle">{subTitle}</div>
        <div className={style.button}>{buttonText}</div>
      </div>
    </Card>
  );
};

const ResourceCardsVariants = ['vertical', 'horizontal'] as const;
type ResourceCardsVariant = (typeof ResourceCardsVariants)[number];

export type ResourceCardsProps = {
  resources: Resource[];
  variant?: ResourceCardsVariant;
  onCardClick?: (resource: Resource) => void;
};

const ResourceCards: React.FC<ResourceCardsProps> = ({ resources, variant, onCardClick }) => {
  return (
    <div
      className={clsx(style.additionalResourcesContainer, {
        [style.horizontal]: variant === 'horizontal',
      })}
    >
      {resources.map(resource => (
        <ResourcesCard
          key={resource.title}
          {...resource}
          href={resource.url}
          onClick={onCardClick && (() => onCardClick(resource))}
        />
      ))}
    </div>
  );
};

export default ResourceCards;
