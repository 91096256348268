// Do not move this to the partner folder as it will cause a circular dependency
export enum PartnerApplication {
  Dot = 'dot',
}

export type PartnerAccount = { partnerAccountId: string; partnerApplication: PartnerApplication };
export type PartnerAccountWithType = { type: 'partner' } & PartnerAccount;

export type Account = { type: 'platform'; accountId: string } | PartnerAccountWithType;

export const platformAccount = (accountId: string): Account => ({ type: 'platform', accountId });
export const partnerAccount = (
  partnerAccountId: string,
  partnerApplication: PartnerApplication,
): PartnerAccountWithType => ({
  type: 'partner',
  partnerAccountId,
  partnerApplication,
});
